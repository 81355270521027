import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Elements';
import { MenuCoverImage } from './MenuCoverImage';

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  color: ${({ theme }) => theme.onLightColorMediumEmphasis};
  border-bottom: 1px solid ${({ theme }) => theme.outlinePrimaryColor};
  background-color: ${({ theme }) => theme.lowEmphasisBackgroundColor};

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .image-container {
    width: 13.75rem;
  }

  .grid {
    margin: 0 auto;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    justify-items: center;
    max-width: 42rem;
  }

  @media (min-width: 50rem) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
    .section-heading,
    .section-subtitle {
      text-align: right;
    }
    .image-container {
      width: 8.75rem;
    }
    .grid-cell__button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`;

const Button = styled.a`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.heightDefault};
  white-space: nowrap;
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: 0.26s;
  border: none;
  width: 100%;
  background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
  color: ${({ theme }) => theme.containedButtonTextColor};
  box-shadow: ${({ theme }) => theme.elevation02};
  text-decoration: none;

  &:hover {
    .state-overlay {
      opacity: 0.08;
    }
    box-shadow: ${({ theme }) => theme.elevation08};
  }
`;

const Section06 = () => {
  //
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="grid-cell grid-cell__button-container">
            <h2 className="roboto-text-lg section-heading">Download Our Menu</h2>
            <div className="roboto-text-base roboto-text-base--subtitle section-subtitle">
              Click to download a copy of our latest menu.
            </div>
            <Button className="roboto-text-sm roboto-text-sm--button" download href="/menu.pdf" target="_blank">
              Download Menu
            </Button>
          </div>
          <div className="grid-cell">
            <a download href="/menu.pdf" target="_blank">
              <div className="image-container">
                <MenuCoverImage />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Section06;
