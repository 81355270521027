/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const QUERY = graphql`
  query {
    spices: file(sourceInstanceName: { eq: "images" }, name: { eq: "spices" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export const SpicesImage = () => {
  //
  const { spices } = useStaticQuery(QUERY);

  return <ImageSharp fluid={spices.childImageSharp.fluid} />;
};
