import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageSEO, useSEO } from '../components/SEO';
import Section01 from '../page-sections/home/Section01';
import Section02 from '../page-sections/home/Section02';
import Section03 from '../page-sections/home/Section03';
import Section04 from '../page-sections/home/Section04';
import Section05 from '../page-sections/home/Section05';
import Section06 from '../page-sections/home/Section06';
import Section07 from '../page-sections/home/Section07';
import Maintanence from '../page-sections/maintanence/Maintanence';

// import { Demo } from '../components/Demo';

const Temp = styled.div`
  background-color: #c04c22;
  font-size: 1.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 3rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;

  h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
    text-decoration: underline;
  }
`;

const Home = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-default.jpg" />
      <Section01 />
      {/* <Temp>
        <div>
          <h2>Announcement:</h2>
          <p>Change of opening hours</p>
          <div style={{ marginBottom: '0.5rem' }}>2nd May: We are open in the evening as usual.</div>
          <div>3rd May: We are open in the evening only.</div>
        </div>
      </Temp> */}
      {/* <Section07 /> */}
      {/* <Section03 /> */}
      <Section02 />
      {/* <Section04 /> */}
      <Section06 />
      <Section05 />
      {/* <Demo /> */}
    </article>
  );
};

export default Home;

Home.propTypes = {
  location: PropTypes.object.isRequired,
};
