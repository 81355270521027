import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../components/Elements';
import { LogoImage } from './LogoImage';
import { LogoType } from '../../components/Svg/LogoType';
import { HeroImage } from './HeroImage';

const Section = styled.section`
  position: relative;
  width: 100%;
  height: 80vh;
  min-height: 44rem;

  .hero-image-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .hero-cta-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 1rem;
    display: grid;
    justify-items: center;
    align-content: center;
  }

  .logomark-container {
    width: 7.5rem;
    margin-bottom: 1rem;
  }

  .logotype-container {
    width: 100%;
    max-width: 12.5rem;
    svg {
      fill: #ffffff;
    }
  }

  .hero-heading {
    max-width: 25rem;
    text-align: center;
    line-height: 1.5;
    color: #ffffff;
    margin-bottom: 1.5rem;
    letter-spacing: 0.125rem;
    font-family: 'Allura';
    font-size: 2rem;
  }

  .hero-subtitle {
    text-align: center;
    letter-spacing: 0.25rem;
    margin-bottom: 2rem;
    color: #ffffff;
  }

  @media (min-width: 50rem) {
    .logomark-container {
      width: 8.25rem;
    }
    .logotype-container {
      max-width: 21rem;
    }
  }
`;

const Section01 = () => {
  //
  return (
    <Section>
      <div className="hero-image-container">
        <HeroImage />
      </div>
      <div className="overlay" />
      <div className="hero-cta-container">
        <div className="logomark-container">
          <LogoImage />
        </div>
        <div className="logotype-container">
          <LogoType />
        </div>
        <div className="roboto-text-sm roboto-text-sm--body hero-subtitle">INDIAN DELICACY</div>
        <h1 className="hero-heading">
          Prepared with Passion <br />& Served with Love
        </h1>
        <LinkButton modifiers={['large', 'contained']} to="/book">
          Book a Table
        </LinkButton>
      </div>
    </Section>
  );
};

export default Section01;
