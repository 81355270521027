import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Elements';
import { PromoSplash1 } from './PromoSplash1';
import { PromoSplash2 } from './PromoSplash2';
import { PromoSplash3 } from './PromoSplash3';

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  color: ${({ theme }) => theme.onLightColorMediumEmphasis};
  border-bottom: 1px solid ${({ theme }) => theme.outlinePrimaryColor};

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .grid {
    margin: 0 auto;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }

  @media (min-width: 50rem) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

const Section03 = () => {
  //
  return (
    <Section>
      <Container>
        <h2 className="roboto-text-xl section-heading">Latest Promotions</h2>
        <div className="roboto-text-base roboto-text-base--subtitle section-subtitle">Click image to enlarge.</div>
        <div className="grid">
          <div className="grid-cell">
            <a href="/promo-1.jpg" target="_blank">
              <PromoSplash1 />
            </a>
          </div>
          <div className="grid-cell">
            <a href="/promo-2.jpg" target="_blank">
              <PromoSplash2 />
            </a>
          </div>
          <div className="grid-cell">
            <a href="/promo-3.jpg" target="_blank">
              <PromoSplash3 />
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Section03;
