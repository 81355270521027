/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const PrawnIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      tabIndex="-1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path d="M15.75,4.5H10.36a2.23,2.23,0,0,0-4.22,0H3a1.5,1.5,0,0,1,0-3H13.5a.76.76,0,0,0,.75-.75A.76.76,0,0,0,13.5,0H3A3,3,0,0,0,3,6H6.14a2.23,2.23,0,0,0,4.22,0h3.89v5.25H9.75A3,3,0,0,1,7,9.4,4.44,4.44,0,0,0,3,6.75a.75.75,0,0,0,0,1.5A2.94,2.94,0,0,1,5.71,10.1a4.44,4.44,0,0,0,4,2.65H15a2.25,2.25,0,0,1,0,4.5H9.75A.76.76,0,0,0,9,18c0,3.15,1.18,5.15,3.5,6a.73.73,0,0,0,.25,0,.81.81,0,0,0,.34-.08.73.73,0,0,0,.37-.44c.59-1.86,1.17-2.48,2.29-2.48a8.25,8.25,0,0,0,0-16.5ZM8.25,6A.75.75,0,1,1,9,5.25.76.76,0,0,1,8.25,6Zm7.5,5.33V6a6.76,6.76,0,0,1,6.39,4.59l-4.21,2.1A3.69,3.69,0,0,0,15.75,11.33Zm-3.44,10.9a4,4,0,0,1-1.78-3.48H15a3.74,3.74,0,0,0,2.91-1.41l1.56,1a6.71,6.71,0,0,1-3.72,1.12C13.8,19.5,12.89,20.73,12.31,22.23Zm8.35-4.86L18.6,16a3.66,3.66,0,0,0,.15-1,3.85,3.85,0,0,0-.14-1l3.86-1.93c0,.22,0,.43,0,.65A6.71,6.71,0,0,1,20.66,17.37Z" />
    </svg>
  );
};
