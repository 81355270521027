/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { slugify } from '../../utils';
import { StarIcon } from '../../components/Svg/StarIcon';
import { FavoriteIcon } from '../../components/Svg/FavoriteIcon';
import { PrawnIcon } from '../../components/Svg/PrawnIcon';
import { SpicyIcon } from '../../components/Svg/SpicyIcon';
import { LeafIcon } from '../../components/Svg/LeafIcon';

const ImageSharp = styled(Img)`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .featured-menu-item__image-container {
    width: 7.5rem;
    display: none;
    margin-right: 1rem;
  }

  .featured-menu-item__title-description {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .featured-menu-item__title {
    margin-bottom: -0.5rem;
    display: flex;
    color: ${({ theme }) => theme.onLightSecondaryColor};

    .food-icons {
      margin-left: 0.5rem;
      display: inline-flex;
      align-items: center;
    }

    .food-icon-container {
      svg {
        width: 18px;
        fill: ${({ theme }) => theme.primaryColor};
        margin-left: 0.125rem;
      }
    }
  }

  .featured-menu-item__description {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    line-height: 1.5;
  }

  .featured-menu-item__money {
    align-self: flex-start;
    margin-left: 1.5rem;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  @media (min-width: 42.5rem) {
    .featured-menu-item__image-container {
      display: block;
    }
  }
`;

export const FeaturedMenuItem = ({ data, placeholderImage }) => {
  //
  const money = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  });

  const image = data.image ? data.image.asset.localFile.childImageSharp.fixed : placeholderImage;

  return (
    <Root>
      {/* <div className="featured-menu-item__image-container">
        <ImageSharp fixed={image} />
      </div> */}
      <div className="featured-menu-item__title-description">
        <div>
          <Link
            className="roboto-text-base featured-menu-item__title"
            to={`/menu/${slugify(data.menuMainCategory.menuMainCategoryName)}`}
            state={{ itemId: data.slug.current }}
          >
            {data.name}
            <div className="food-icons">
              <span className="food-icon-container">{data.vegetarian && <LeafIcon />}</span>
              <span className="food-icon-container">{data.featured && <StarIcon />}</span>
              <span className="food-icon-container">{data.favorite && <FavoriteIcon />}</span>
              <span className="food-icon-container">{data.spicy && <SpicyIcon />}</span>
              <span className="food-icon-container">{data.prawn && <PrawnIcon />}</span>
            </div>
          </Link>
          <div className="roboto-text-sm roboto-text-sm--body featured-menu-item__description">{data.description}</div>
        </div>
        <div className="featured-menu-item__money roboto-text-base">{money.format(data.variants[0].variantPrice)}</div>
      </div>
    </Root>
  );
};

FeaturedMenuItem.propTypes = {
  data: PropTypes.object.isRequired,
  placeholderImage: PropTypes.object.isRequired,
};
