/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const SpicyIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      tabIndex="-1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path d="M15.68,3.06A3.75,3.75,0,0,0,12,0a.76.76,0,0,0-.75.75A.76.76,0,0,0,12,1.5a2.25,2.25,0,0,1,2.14,1.58A4.52,4.52,0,0,0,10.5,7.5v3.75A8.26,8.26,0,0,1,2.25,19.5a2.25,2.25,0,0,0,0,4.5H3A16.52,16.52,0,0,0,19.5,7.5,4.5,4.5,0,0,0,15.68,3.06ZM3,22.5H2.25a.75.75,0,0,1,0-1.5A9.76,9.76,0,0,0,12,11.25V8.79l1.44-.48,1.22.61a.74.74,0,0,0,.68,0l1.5-.75a.75.75,0,1,0-.68-1.34L15,7.41l-1.16-.58a.78.78,0,0,0-.58,0L12,7.2a3,3,0,0,1,6,.3A15,15,0,0,1,3,22.5Z" />
    </svg>
  );
};
