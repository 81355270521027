/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const LeafIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      tabIndex="-1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path d="M0,0S1.2,13.19,6,18c4.2,4.2,9.31,3.8,12.13,2l3.58,3.57,0,0a1.33,1.33,0,1,0,1.85-1.92L20,18.13C21.79,15.31,22.19,10.2,18,6a17.4,17.4,0,0,0-6.55-3.6L9.33,6.66,7.41,1.25A60,60,0,0,0,0,0Z" />
    </svg>
  );
};
