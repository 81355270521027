/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const QUERY = graphql`
  query {
    filename: file(sourceInstanceName: { eq: "images" }, name: { eq: "promo-1-l" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export const Promo1l = () => {
  //
  const { filename } = useStaticQuery(QUERY);

  return <ImageSharp fluid={filename.childImageSharp.fluid} />;
};
