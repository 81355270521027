/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;

  .bullet {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin: 0 4px;
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }

  .checked {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const PaginationBullets = ({ currOffset, carouselWidth, cardWidth, gutterWidth, numOfReviews }) => {
  //
  const translateWidth = cardWidth + gutterWidth;
  const start = Math.abs(currOffset) / translateWidth;
  const end = Math.ceil(carouselWidth / translateWidth) + start;

  const bullets = [];
  for (let i = 0; i < numOfReviews; i += 1) {
    bullets.push(<div key={i} className={i >= start && i < end ? `bullet checked` : `bullet`} />);
  }

  return <Root>{bullets}</Root>;
};

PaginationBullets.propTypes = {
  currOffset: PropTypes.number.isRequired,
  carouselWidth: PropTypes.number.isRequired,
  cardWidth: PropTypes.number.isRequired,
  gutterWidth: PropTypes.number.isRequired,
  numOfReviews: PropTypes.number.isRequired,
};
