import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container } from '../../components/Elements';
import { ManagerImage } from './ManagerImage';
import { OutdoorImage } from './OutdoorImage';
import { SpicesImage } from './SpicesImage';

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  color: ${({ theme }) => theme.onLightColorMediumEmphasis};

  .string-links {
    color: ${({ theme }) => theme.primaryColor};
  }

  .grid {
    max-width: 42.5rem;
    margin: 0 auto;
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    .shop-image {
      grid-area: shopImage;
    }

    .about-us-copy {
      grid-area: aboutUsCopy;
    }

    .manager-image {
      grid-area: managerImage;
    }

    .manager-copy {
      grid-area: managerCopy;
    }

    grid-template-columns: 1fr;

    grid-template-areas:
      'shopImage'
      'aboutUsCopy'
      'managerImage'
      'managerCopy';
  }

  .manager__quote {
    line-height: 1.5;
    margin-bottom: 1rem;
    position: relative;
  }

  .manager__title {
    /* color: ${({ theme }) => theme.onLightSecondaryColor}; */
    font-weight: 600;
  }

  .outdoor-image-container {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 46rem) {
    .grid {
      max-width: 75rem;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'aboutUsCopy shopImage'
        'managerImage managerCopy';
    }
  }

  @media (min-width: 75rem) {
    .grid {
      grid-column-gap: 6rem;
    }
    padding: 8rem 0;
  }
`;

const Section02 = () => {
  //
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="shop-image">
            <div className="outdoor-image-container photoframe">
              <OutdoorImage />
            </div>
          </div>
          <div className="about-us-copy">
            <h2 className="roboto-text-xl section-heading">About Tandoori Zaika</h2>
            <div className="roboto-text-base roboto-text-base--body">
              <p>
                The passion for food brings 3 food lovers & friends together, to deliver quality dining with authentic
                and real flavours of Indian food to residents and visitors of Singapore (City in a Garden).
              </p>
              <p>
                Tandoori Zaika was formed based on the core values of{' '}
                <Link className="string-links" to="/about-us#core-1">
                  Atithi Devo Bhava [अतिथिदेवो भव]
                </Link>
                ,{' '}
                <Link className="string-links" to="/about-us#core-2">
                  authenticity [real Zaika's (flavours)]
                </Link>
                ,{' '}
                <Link className="string-links" to="/about-us#core-3">
                  integrity
                </Link>
                ,{' '}
                <Link className="string-links" to="/about-us#core-4">
                  passion
                </Link>
                ,{' '}
                <Link className="string-links" to="/about-us#core-5">
                  quality & consistency
                </Link>{' '}
                and{' '}
                <Link className="string-links" to="/about-us#core-6">
                  customer experience
                </Link>
                .
              </p>
              <p>
                Our restaurant is based in Boat Quay Singapore along the bank of Singapore River, providing alfresco
                dining and great ambience with view of the{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Parliament_House,_Singapore"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Parliament House
                </a>
                ,{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Empress_Place_Building"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Empress Place Building
                </a>
                ,{' '}
                <a
                  href="https://en.wikipedia.org/wiki/The_Fullerton_Hotel"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Fullerton Hotel
                </a>{' '}
                and{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Marina_Bay_Sands"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Marina Bay Sands.
                </a>
              </p>
            </div>
          </div>
          <div className="manager-image">
            <div className="outdoor-image-container photoframe">
              <ManagerImage />
            </div>
          </div>
          <div className="manager-copy">
            <h2 className="roboto-text-base section-heading">Message from Restaurant Manager</h2>
            <div className="roboto-text-base roboto-text-base--body">
              <p>🙏 Namaste 🙏</p>
              <p>
                We provide quality dining with a great ambience at Boat Quay. Run by the team who used to manage Haldhi
                Restaurant in Boat Quay, at Tandoori Zaika we serve Authentic Indian Zaika (flavour) prepared with
                passion and served with love.
              </p>
              <p>
                We will take good care of your friends &amp; family, and we will try our best to meet any special
                requirements you may have. Our team is focused on making your dining experience at Tandoori Zaika a
                memorable one.
              </p>
              <p>A great experience awaits, visit us today at Tandoori Zaika.</p>
              <div className="roboto-text-base manager__title">Raju Gawda</div>
              <div className="roboto-text-base">Manager</div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Section02;
