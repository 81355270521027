/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import { Promo1l } from './Promo1l';
import { Promo1p } from './Promo1p';
import { Promo2l } from './Promo2l';
import { Promo2p } from './Promo2p';
import { Promo3 } from './Promo3';

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  color: ${({ theme }) => theme.onLightColorMediumEmphasis};

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .slider-container {
    width: 100%;
    margin: 0 auto;
    position: relative;

    .slick-dots {
      position: absolute;
      padding: 0;
      list-style-type: none;
      text-align: center;
      bottom: -3rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      li {
        display: inline-block;
      }

      button {
        height: 0.75rem;
        width: 0.75rem;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.primaryColor};
        color: transparent;
        margin: 0 0.35rem;
        padding: 0;
      }
      .slick-active {
        button {
          background-color: ${({ theme }) => theme.primaryColor};
        }
      }
    }

    .slick-arrow {
      font-size: 0;
      height: 2rem;
      width: 2rem;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      cursor: pointer;
      border: none;
      background-color: transparent;
      z-index: 1;
    }

    .slick-prev {
      left: 0;
      z-index: 1;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev:before {
      content: '❮';
      border-bottom-right-radius: 4rem;
      border-top-right-radius: 4rem;
      padding-left: 0.35rem;
    }

    .slick-next::before {
      content: '❯';
      border-bottom-left-radius: 4rem;
      border-top-left-radius: 4rem;
      justify-content: flex-end;
      padding-right: 0.35rem;
    }

    .slick-next:before,
    .slick-prev:before {
      transition: 0.2s;
      color: #fff;
      height: 4rem;
      width: 2rem;
      background-color: #000000;
      position: absolute;
      top: -1rem;
      left: 0;
      display: none;
      align-items: center;
      font-size: 1.5rem;
      opacity: 0.25;
      display: flex;
    }

    .slick-prev:hover:before,
    .slick-next:hover:before {
      opacity: 1;
    }
  }

  .image-container {
    position: relative;
    width: 100vw;
    .image {
      object-fit: cover;
    }

    .image-large {
      display: none;
    }
  }

  @media (min-width: 42rem) {
    .slider-container {
      .image-small {
        display: none;
      }
      .image-large {
        display: block;
      }
    }
  }
`;

const sliderOptions = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

const Section07 = () => {
  //
  const sliderRef = useRef();

  return (
    <Section>
      <h2 className="roboto-text-xl section-heading">Latest Promotions</h2>
      <div className="roboto-text-base roboto-text-base--subtitle section-subtitle">
        Swipe to see more or{' '}
        <Link to="/promotions" className="link">
          click here for all promotions
        </Link>
        .
      </div>
      <div className="slider-container">
        <Slider {...sliderOptions} ref={sliderRef}>
          <div className="image-container">
            <div className="image image-small">
              <Promo1p />
            </div>
            <div className="image image-large">
              <Promo1l />
            </div>
          </div>
          <div className="image-container">
            <div className="image image-small">
              <Promo2p />
            </div>
            <div className="image image-large">
              <Promo2l />
            </div>
          </div>
          {/* <div className="image-container">
            <div className="image image-small">
              <Promo3 />
            </div>
            <div className="image image-large">
              <Promo3 />
            </div>
          </div> */}
        </Slider>
      </div>
    </Section>
  );
};

export default Section07;
