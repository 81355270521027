import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from '../../components/Elements';
import { FeaturedMenuItem } from './FeaturedMenuItem';
import { LogoImage } from './LogoImage';
import { StarIcon } from '../../components/Svg/StarIcon';
import { FavoriteIcon } from '../../components/Svg/FavoriteIcon';
import { LeafIcon } from '../../components/Svg/LeafIcon';

const Query = graphql`
  query {
    allSanityMenu(filter: { featured: { eq: true } }, limit: 30) {
      edges {
        ...allMenu
        ...sanityImageFixed120
      }
    }
    placeholder: file(sourceInstanceName: { eq: "images" }, name: { eq: "logo" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  border-top: 1px solid ${({ theme }) => theme.outlinePrimaryColor};
  border-bottom: 1px solid ${({ theme }) => theme.outlinePrimaryColor};

  .section-heading {
    margin: 0 0 1.5rem 0;
    text-align: center;
  }

  .overlapped-logo {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    height: 4rem;
    width: 4rem;
    top: -2rem;
  }

  .featured-menu-item-grid {
    display: grid;
    grid-row-gap: 1.5rem;
    max-width: 42.5rem;
    margin: 0 auto;
  }

  .legends {
    padding: 0 1rem;
    .inner {
      padding: 0.25rem 0;
      box-shadow: ${({ theme }) => theme.elevation02};
      background-color: ${({ theme }) => theme.secondaryVariantColor};
      justify-content: center;
      color: ${({ theme }) => theme.onDarkColorHighEmphasis};
      max-width: 600px;
      margin: 2rem auto 0;
      border-radius: 4px;
      display: flex;
      text-align: center;
      svg {
        width: 16px;
        fill: ${({ theme }) => theme.primaryColor};
      }
      .legend {
        margin: 0 1rem;
      }
      .legend-icon {
        display: block;
      }
    }
    position: sticky;
    bottom: 1rem;
  }

  @media (min-width: 32rem) {
    .legends {
      .inner {
        svg {
          margin-right: 0.25rem;
        }
        .legend-icon {
          display: inline-block;
        }
      }
    }
  }

  @media (min-width: 75rem) {
    .section-heading {
      margin: 0 0 2.5rem 0;
    }
    .featured-menu-item-grid {
      grid-template-columns: 1fr 1fr;
      max-width: 75rem;
      grid-column-gap: 6rem;
    }
    .overlapped-logo {
      height: 6rem;
      width: 6rem;
      top: -3rem;
    }
    padding: 8rem 0;
  }
`;

const Section04 = () => {
  //
  const {
    allSanityMenu: { edges: featuredItems },
    placeholder: {
      childImageSharp: { fixed: placeholderImage },
    },
  } = useStaticQuery(Query);

  return (
    <Section>
      <div className="overlapped-logo">
        <LogoImage />
      </div>
      <Container>
        <div>
          <h2 className="roboto-text-xl section-heading">Our Specials</h2>
          <div className="featured-menu-item-grid">
            {featuredItems.map((item) => {
              return <FeaturedMenuItem key={item.node.id} data={item.node} placeholderImage={placeholderImage} />;
            })}
          </div>
        </div>
      </Container>
      <div className="legends">
        <div className="inner">
          <div className="roboto-text-xs legend">
            <span className="legend-icon">
              <LeafIcon />
            </span>
            Vegetarian
          </div>
          <div className="roboto-text-xs legend">
            <span className="legend-icon">
              <StarIcon />
            </span>
            Chef/TZ Special
          </div>
          <div className="roboto-text-xs legend">
            <span className="legend-icon">
              <FavoriteIcon />
            </span>
            Patron Favourites
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Section04;
