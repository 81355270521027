import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useGoogleReview } from '../../components/SEO';
import { Container } from '../../components/Elements';
import { PaginationBullets } from './PaginationBullets';
import { ReviewCarousel } from './ReviewCarousel';
import { scrapedReviews } from '../reviews/scrapedReviews';

const Section = styled.section`
  position: relative;
  padding: 4rem 0;

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }
`;

const Section05 = () => {
  //

  // const { reviews } = useGoogleReview();
  const [currOffset, setCurrOffset] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);

  const cardWidth = 320;
  const gutterWidth = 16;
  const swipePower = 0.7;

  const parsedReviews = scrapedReviews.reduce((acc, review) => {
    if (review.review_rating === '5') {
      acc = [
        ...acc,
        {
          reviewerImageUrl: review.autor_image,
          reviewerName: review.autor_name,
          localDate: new Date(review.review_timestamp * 1000).toLocaleDateString(),
          ratingValue: parseFloat(review.review_rating),
          reviewBody: review.review_text,
          reviewLink: review.review_link,
          response: review.owner_answer,
          ts: review.review_timestamp,
        },
      ];
    }
    return acc;
  }, []);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  parsedReviews.sort(dynamicSort('-ts'));

  const reviews = parsedReviews.slice(0, 5);

  return (
    <Section>
      <Container>
        <h2 className="roboto-text-lg section-heading">Recent Customer Reviews</h2>
        <div className="roboto-text-base roboto-text-base--subtitle section-subtitle">
          Swipe to see more or{' '}
          <Link to="/reviews" className="link">
            click here for full reviews
          </Link>
          .
        </div>
        <PaginationBullets
          carouselWidth={carouselWidth}
          currOffset={currOffset}
          cardWidth={cardWidth}
          gutterWidth={gutterWidth}
          numOfReviews={reviews.length}
        />
        <ReviewCarousel
          carouselWidth={carouselWidth}
          setCarouselWidth={setCarouselWidth}
          currOffset={currOffset}
          setCurrOffset={setCurrOffset}
          cardWidth={cardWidth}
          gutterWidth={gutterWidth}
          swipePower={swipePower}
          reviews={reviews}
        />
      </Container>
    </Section>
  );
};

export default Section05;
