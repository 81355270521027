/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useWindowSize } from '../../utils';
import { ReviewCard } from './ReviewCard';
import { ChevronLeftIcon } from '../../components/Svg/ChevronLeftIcon';
import { ChevronRightIcon } from '../../components/Svg/ChevronRightIcon';

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 25rem;
  margin: 0 auto;
  height: 22rem;
  padding: 1rem 0;

  .inner-container {
    position: relative;
    width: 20rem;
    margin: 0 auto;
  }

  .card-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: ${(props) => props.gutterWidth}px;
    width: ${(props) => props.cardRowWidth}px;
    position: relative;
  }

  .left-side {
    background-image: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.defaultSurfaceE00}, ${theme.defaultSurfaceE00}, ${theme.defaultTransparentSurfaceE00})`};
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    width: 2.5rem;
    left: -2.5rem;
  }

  .right-side {
    background-image: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.defaultTransparentSurfaceE00}, ${theme.defaultSurfaceE00}, ${theme.defaultSurfaceE00})`};
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    width: 2.5rem;
    right: -2.5rem;
  }

  @media (min-width: 43rem) {
    .inner-container {
      width: 41rem;
    }
    max-width: 46rem;
  }
  @media (min-width: 64rem) {
    .inner-container {
      width: 62rem;
    }
    max-width: 67rem;
  }
  @media (min-width: 75rem) {
    max-width: 72rem;
    .left-side,
    .right-side {
      width: 5rem;
    }
    .left-side {
      left: -5rem;
    }
    .right-side {
      right: -5rem;
    }
  }
`;

const Paginate = styled.div`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: none;

  &.left {
    left: 0.5rem;
  }
  &.right {
    right: 0.5rem;
  }

  button {
    background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
    display: flex;
    padding: 0;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    border: none;
    border-radius: 50%;
    height: ${({ theme }) => theme.heightDefault};
    width: ${({ theme }) => theme.heightDefault};
    svg {
      pointer-events: none;
      fill: ${({ theme }) => theme.onDarkColorHighEmphasis};
    }
    :disabled {
      background-color: ${({ theme }) => theme.onLightColorDisabled};
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  @media (min-width: 75rem) {
    display: block;
  }
`;

export const ReviewCarousel = ({
  carouselWidth,
  setCarouselWidth,
  currOffset,
  setCurrOffset,
  cardWidth,
  gutterWidth,
  swipePower,
  reviews,
}) => {
  //
  const animation = useAnimation();
  const { width } = useWindowSize();
  const carouselRef = useRef();
  const cardRowRef = useRef();
  const translateWidth = cardWidth + gutterWidth;
  const minOffSet = cardWidth * swipePower;
  const [cardRowWidth, setCardRowWidth] = useState(null);

  useEffect(() => {
    //
    setCardRowWidth(reviews.length * cardWidth + (reviews.length - 1) * gutterWidth);
  }, []);

  useEffect(() => {
    //
    if (carouselRef) {
      const currWidth = carouselRef.current.getBoundingClientRect().width;
      if (currWidth !== carouselWidth) {
        setCarouselWidth(currWidth);
      }
    }
  }, [width]);

  useEffect(() => {
    //
    setCurrOffset(0);
    animation.start('initial');
  }, [carouselWidth]);

  const variants = {
    shift: {
      x: currOffset,
    },
    noshift: {
      x: currOffset,
    },
    initial: {
      x: 0,
    },
  };

  const handleDrag = async (_, info) => {
    //
    const offset = Math.abs(info.offset.x);
    const posShifted = Math.floor((offset + minOffSet) / cardWidth);

    if (posShifted > 0) {
      if (info.offset.x < 0) {
        // Shifting content to the left
        const maxOffset = cardRowWidth - carouselWidth + currOffset;
        const amountToTranslate = posShifted * translateWidth <= maxOffset ? posShifted * translateWidth : maxOffset;
        setCurrOffset((prevState) => prevState - amountToTranslate);
        await animation.start('shift');
      }
      if (info.offset.x > 0) {
        // Shifting content to the right
        const maxOffset = Math.abs(currOffset);
        const amountToTranslate = posShifted * translateWidth <= maxOffset ? posShifted * translateWidth : maxOffset;
        setCurrOffset((prevState) => prevState + amountToTranslate);
        await animation.start('shift');
      }
    }
    if (posShifted === 0) {
      await animation.start('noshift');
    }
  };

  const paginateReview = async (dir) => {
    if (dir === 'left') {
      const maxOffset = cardRowWidth + translateWidth - carouselWidth + currOffset;
      const amountToTranslate = translateWidth <= maxOffset ? translateWidth : maxOffset;
      await setCurrOffset((prevState) => prevState - amountToTranslate);
      await animation.start('shift');
    }
    if (dir === 'right') {
      const maxOffset = Math.abs(currOffset);
      const amountToTranslate = translateWidth <= maxOffset ? translateWidth : maxOffset;
      await setCurrOffset((prevState) => prevState + amountToTranslate);
      await animation.start('shift');
    }
  };

  return (
    <Root cardRowWidth={cardRowWidth} gutterWidth={gutterWidth}>
      <div className="inner-container" ref={carouselRef}>
        <motion.div
          className="card-row"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={handleDrag}
          dragMomentum={false}
          dragElastic={1}
          variants={variants}
          animate={animation}
          ref={cardRowRef}
        >
          {reviews.map((review) => {
            return <ReviewCard key={review.id} data={review} />;
          })}
        </motion.div>
        <div className="left-side" />
        <div className="right-side" />
      </div>
      <Paginate className="left">
        <button
          type="button"
          aria-label="move review cards left"
          onClick={() => paginateReview('left')}
          disabled={Math.abs(currOffset) === cardRowWidth - carouselWidth}
        >
          <ChevronLeftIcon />
        </button>
      </Paginate>
      <Paginate className="right">
        <button
          type="button"
          aria-label="move review cards right"
          onClick={() => paginateReview('right')}
          disabled={currOffset === 0}
        >
          <ChevronRightIcon />
        </button>
      </Paginate>
    </Root>
  );
};

ReviewCarousel.propTypes = {
  carouselWidth: PropTypes.number.isRequired,
  setCarouselWidth: PropTypes.func.isRequired,
  currOffset: PropTypes.number.isRequired,
  setCurrOffset: PropTypes.func.isRequired,
  cardWidth: PropTypes.number.isRequired,
  gutterWidth: PropTypes.number.isRequired,
  swipePower: PropTypes.number.isRequired,
  reviews: PropTypes.array.isRequired,
};
